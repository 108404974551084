import { AggregatedOrderItem, generateAggregatedOrderItems } from "./AggregatedOrderItem";
import { MenuItem, SerializedMenuItem, deserializeMenuItem } from "./MenuItem";
import { OrderItem, SerializedOrderItem, deserializeOrderItem } from "./OrderItem";

export enum OrderStatus {
    PENDING = "PENDING", //only exists in the localstorage of the client
    PLACED = "PLACED",
    ACCEPTED = "ACCEPTED",
    SERVED = "SERVED",
    PAID = "PAID",
    CANCELLED = "CANCELLED" //???
}

export interface Order {
    id: number;
    tableId: number;
    items: OrderItem[];
    price: number;
    note: string;
    code: string;
    orderStatus: OrderStatus;
    aggregatedOrderitems: AggregatedOrderItem[];
}

export interface SerializedOrder {
    id: number;
    tableId: number;
    items: SerializedOrderItem[];
    price: string;
    note: string;
    code: string;
    orderStatus: OrderStatus;
}


export interface SerializedStoredOrder extends SerializedOrder {
    createdAt: number;
    retentionTime: number;
}

export interface StoredOrder extends Order {
    createdAt: number;
    retentionTime: number;
}

export interface PlaceOrderRequestBody {
    tableId: number;
    note: string;
    orderItems: PlaceOrderItemRequestBody[];
}

interface PlaceOrderItemRequestBody {
    note: string;
    menuItemId: number;
}


export function deserializeOrder(order: SerializedOrder, sessionId: string): Order {
    const deserializedItems = order.items.map(deserializeOrderItem);
    const newOrder: Order = {
        ...order,
        price: parseFloat(order.price),
        items: deserializedItems,
        aggregatedOrderitems: []
    }
    return {
        ...newOrder,
        aggregatedOrderitems: generateAggregatedOrderItems([newOrder], sessionId)
    }
}

export function deserializeStoredOrder(order: SerializedStoredOrder, sessionId: string): StoredOrder {
    const deserializedItems = order.items.map(deserializeOrderItem);
    const newOrder: StoredOrder = {
        ...order,
        price: parseFloat(order.price),
        items: deserializedItems,
        aggregatedOrderitems: []
    }
    return {
        ...newOrder,
        aggregatedOrderitems: generateAggregatedOrderItems([newOrder], sessionId)
    }
}