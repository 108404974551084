import { Container, Box, Typography } from '@mui/material'
import { primaryText } from '../../styles/colors';
import ErrorIcon from '@mui/icons-material/Error';

type Props = {
    errorText: string;
}

const PageError = (props: Props) => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <div>
                    <ErrorIcon sx={{ fontSize: 100 }} />
                </div>
                <div>
                    <Typography align="center" sx={{ color: primaryText }}>
                        {props.errorText}
                    </Typography>
                </div>
            </Box>
        </Container>
    )
}

export default PageError