import mainEn from "../en/mainEn";
import commonHu from "./commonHu";
import menuHU from "./menuHu";
import navigationHu from "./navigationHu";
import orderHu from "./orderHu";
import paymentHu from "./paymentHu";

const mainHu: typeof mainEn = {
    common: commonHu,
    navigation: navigationHu,
    menu: menuHU,
    order: orderHu,
    payment: paymentHu
}

export default mainHu;