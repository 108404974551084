import commonEn from "../en/commonEn";

const commonHu: typeof commonEn = {
    "restaurant": "Étterem",
    "restaurant_not_found": "A keresett étterem nem elérhető",
    "table_not_available": "Ennél az asztalnál nem adható le rendelés",
    "note": "Megjegyzés",
    "order": "Rendelés",
    "internal_error": "Kérés sikertelen. A szerver oldali hiba: ",
    "no_results": "Nincs találat",
    "cancel": "Mégse",
    "save": "Mentés",
    "back": "Vissza",
    "terms_placeholder": "Itt fognak megjelenni a felhasználási feltételeink."
 }
 
 export default commonHu;
