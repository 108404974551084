const menuHU = {
    "add_to_cart": "Hozzáadás a kosárhoz",
    "add_to_order": "Hozzáadás a rendeléshez",
    "already_in_cart": "Már a kosaradban",
    "update_order_item": "Tétel frissítése",
    "delete_order_item": "Tétel eltávolítása",
    "available_categories": "Menü kategóriák",
    "search_menu_placeholder": "Keresés a menüben",
    "guides": {
        "scan_qr": "Scanneld be az asztalon lévő QR kódot a telefonoddal",
        "order": "Rendeld meg a telefonodról a kiválasztott termékeket",
        "pay": "Fizessetek külön vagy egyben a telefonodról",
        "accept": "Tovább az étlapra",
    }
}

export default menuHU;