import styled from "styled-components";
import { altText, lightBackground, primary, primaryBackground, primaryBorder } from "./colors";

export const RestaurantHeaderContainer = styled.div`
    
    background: linear-gradient(${lightBackground}, ${lightBackground}, ${lightBackground}, ${primaryBackground}, ${primaryBackground});
    box-shadow: 0px 0px 5px 0px ${primaryBorder};
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    height: ${props => props.theme.height}px;
    will-change: height;
    z-index: 100;
    animation: moveInFromTop 0.4s ease;
`;

export const HeaderTitleContent = styled.div`
    padding-top: ${props => props.theme.paddingTop}px;
    will-change: padding;
`;

export const HeaderTitleContainer = styled.div`
    width: 100%;
    height: calc(100% - 1.625rem);
    position: relative;
`;

export const HeaderChildTitleContainer = styled.div`
    display: inline-block;
    width: 100%;
    background: white;
    text-align: center;
`;

export const HeaderChildTitle = styled.div`
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0 10px;
    padding-top: 6px;
    height: 1.625rem;
    max-height: 1.625rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: ${primary}; 
    color: ${altText};
    margin: auto;
`;

export const HeaderCildrenContainer = styled.div`
    height: 1.625rem; 
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const BottomFloat = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    animation: fadeIn 0.2s ease-in;
`;