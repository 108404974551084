import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Order, OrderStatus, PlaceOrderRequestBody, SerializedOrder, deserializeOrder } from '../model/Order'
import { Outlet } from 'react-router-dom';
import TableContext from './TableContext';
import useFetch from '../hooks/useFetch';
import { baseURL, getOpenOrders, putPlaceOrder } from '../config/apiRoutes';
import SnackbarContext from './SnackbarContext';
import { useTranslation } from 'react-i18next';
import { AggregatedOrderItem, UserPaymentItem, generateAggregatedOrderItems } from '../model/AggregatedOrderItem';
import { SerializedOrderItem, deserializeOrderItem } from '../model/OrderItem';
import SessionContext from './SessionContext';
import useLocalCart, { UseLocalCartHook } from '../hooks/useLocalCart';

interface OrderContextState extends UseLocalCartHook {
    placeOrder: () => Promise<boolean>;
    openOrders: Order[];
    aggregatedCartOrderItems: AggregatedOrderItem[];
    markOrderItemsForPayment: (itemHash: string, count: number) => Promise<void>;
    unmarkOrderItemsForPayment: (itemHash: string, count: number) => Promise<void>;
    paidByMePrice: number;
    myPayments: UserPaymentItem[];
    initiatePayment: () => Promise<boolean>;
    markAllOrderItems: () => Promise<void>;
    unmarkAllOrderItems: () => Promise<void>;
    selectedPaymentMethod: number | null;
    setSelectedPaymentMethod: (method: number | null) => void;
    fetchOpenOrders: () => Promise<void>;
    lastPayment: React.MutableRefObject<AggregatedOrderItem[]>;
    startAutoUpdate: () => void;
    stopAutoUpdate: () => void;
}

const OrderContext = React.createContext<OrderContextState>(null as any);

//const signalKey = "order-count";

export const OrderProvider = () => {
    const { showMessage } = useContext(SnackbarContext);
    const { t } = useTranslation();
    const { currentTable } = useContext(TableContext);
    const putOrderCaller = useFetch();
    //TEMP
    const [openOrders, setOpenOrders] = useState<Order[]>([]);
    const openOrderCaller = useFetch();
    const { session } = useContext(SessionContext);
    const localCart = useLocalCart(session, currentTable)
    const orderItemMarkCaller = useFetch();
    const paymentCaller = useFetch();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);
    const lastPayment = useRef<AggregatedOrderItem[]>([]);
    const aggregatedOpenOrders = useMemo(() => {
        return generateAggregatedOrderItems(openOrders, session.sessionId).filter(o => o.payableQuantity > 0);
    }, [openOrders, session.sessionId]);

    const updateInterval = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        fetchOpenOrders();
    }, []);

    function startAutoUpdate() {
        if (updateInterval.current == null) {
            updateInterval.current = setInterval(() => {
                fetchOpenOrders();
            }, 3000);
        }
    }

    function stopAutoUpdate() {
        if (updateInterval.current != null) {
            clearInterval(updateInterval.current);
            updateInterval.current = null;
        }
    }

    async function fetchOpenOrders() {
        const response = await openOrderCaller.call<undefined, SerializedOrder[]>({ path: getOpenOrders.path.replace(":tableId", currentTable.id.toString()), method: "GET" });
        if (response.ok) {
            const deserializedOrders = response.data?.map(o => deserializeOrder(o, session.sessionId)) ?? [];
            setOpenOrders(deserializedOrders);
        }
    }

    async function placeOrder() {
        if (localCart.cart == null) return false;
        const request: PlaceOrderRequestBody = {
            tableId: currentTable.id,
            note: localCart.cart.note,
            orderItems: localCart.cart.items.map(item => {
                return {
                    menuItemId: item.menuItemId,
                    note: item.note
                }
            })
        }
        const response = await putOrderCaller.call(putPlaceOrder, request);
        if (response.ok) {
            localCart.clearCart();
            fetchOpenOrders();
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (putOrderCaller.error) {
            showMessage(t("common.internal_error") + putOrderCaller.errorMessage, "error");
        }
    }, [putOrderCaller.error]);


    async function markOrderItemsForPayment(itemHash: string, count: number) {
        const response = await orderItemMarkCaller.call({ path: baseURL + "/order/mark-order-items", method: "POST" }, {
            tableId: currentTable.id,
            itemHash: itemHash,
            markCount: count,
            sessionId: session.sessionId
        });
        if (response.ok) {
            fetchOpenOrders();
        }
    }
    //body.tableId, body.itemHash, body.markCount, body.sessionId 
    async function unmarkOrderItemsForPayment(itemHash: string, count: number) {
        const response = await orderItemMarkCaller.call({ path: baseURL + "/order/unmark-order-items", method: "POST" }, {
            tableId: currentTable.id,
            itemHash: itemHash,
            markCount: count,
            sessionId: session.sessionId
        });
        if (response.ok) {
            fetchOpenOrders();
        }
    }

    const myPayments: UserPaymentItem[] = useMemo(() => {
        return aggregatedOpenOrders
            .filter(o => o.markedByMe > 0)
            .map(o => {
                return {
                    itemHash: o.itemHash,
                    menuItem: o.menuItem,
                    unitPrice: o.unitPrice,
                    quantity: o.markedByMe,
                    sumPrice: o.markedByMe * o.unitPrice
                }
            });
    }, [aggregatedOpenOrders]);

    const paidByMePrice = useMemo(() => {
        return aggregatedOpenOrders.reduce((acc, item) => {
            return item.markedByMe * item.unitPrice + acc;
        }, 0);
    }, [aggregatedOpenOrders]);


    async function initiatePayment() {
        //this is a placeholder API call
        const response = await paymentCaller.call({ path: baseURL + "/order/fulfill-order-items", method: "POST" }, {
            sessionId: session.sessionId,
            tableId: currentTable.id
        });
        if (!response.ok) {
            showMessage(t("common.internal_error"), "error");
            return false;
        }
        const allOrderItems = openOrders.flatMap(o => o.items);
        fetchOpenOrders();
        const affectedItems = (response.data as SerializedOrderItem[]).map(o => deserializeOrderItem(o));
        affectedItems.forEach(item => {
            const oldItem = allOrderItems.find(i => i.id === item.id);
            item.menuItem = oldItem?.menuItem ?? item.menuItem;
        });
        lastPayment.current = generateAggregatedOrderItems([{
            id: 0,
            tableId: 1,
            price: 0,
            note: "",
            code: "",
            orderStatus: OrderStatus.PAID,
            items: affectedItems,
            aggregatedOrderitems: []
        }], session.sessionId);
        return true;
    }

    async function markAllOrderItems() {
        const response = await paymentCaller.call({ path: baseURL + "/order/mark-all-order-items", method: "POST" }, {
            sessionId: session.sessionId,
            tableId: currentTable.id
        });
        if (response.ok) {
            fetchOpenOrders();
        }
    }

    async function unmarkAllOrderItems() {
        const response = await paymentCaller.call({ path: baseURL + "/order/unmark-all-order-items", method: "POST" }, {
            sessionId: session.sessionId,
            tableId: currentTable.id
        });
        if (response.ok) {
            fetchOpenOrders();
        }
    }

    const state = {
        placeOrder,
        openOrders,
        aggregatedOpenOrders,
        markOrderItemsForPayment,
        unmarkOrderItemsForPayment,
        paidByMePrice,
        myPayments,
        initiatePayment,
        markAllOrderItems,
        unmarkAllOrderItems,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        fetchOpenOrders,
        lastPayment,
        startAutoUpdate,
        stopAutoUpdate,
        ...localCart
    }

    return (
        <OrderContext.Provider value={state}>
            <Outlet />
        </OrderContext.Provider>
    )
}

export default OrderContext