import React, { createContext, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import RestaurantHeader from '../components/navigation/RestaurantHeader'
import { HeaderTitleContainer, HeaderChildTitleContainer, HeaderChildTitle } from '../styles/navigation'
import MenuCategoryHeader from '../components/menu/MenuCategoryHeader'
import RestaurantContext from './RestaurantContext'

type Props = {}

type RestoLayoutContextProps = {
    displayOptions: ResoHeaderDisplayOptions;
    setDisplayOptions: React.Dispatch<React.SetStateAction<ResoHeaderDisplayOptions>>;
}

const RestoLayoutContext = createContext<RestoLayoutContextProps>({} as any);

export interface ResoHeaderDisplayOptions {
    displayMode: RestoHeaderDisplayModes;
    title: string;
    displayBack: boolean;
}

export enum RestoHeaderDisplayModes {
    TITLE,
    SLIDER
}

export const RestoLayoutProvider = (props: Props) => {
    const [displayOptions, setDisplayOptions] = useState<ResoHeaderDisplayOptions>({
        displayMode: RestoHeaderDisplayModes.TITLE,
        title: "",
        displayBack: false,

    });
    const { menuCategories } = useContext(RestaurantContext);

    const state = {
        displayOptions,
        setDisplayOptions
    }

    return (
        <RestoLayoutContext.Provider value={state}>
            <RestaurantHeader
                displayBack={displayOptions.displayBack}
            >
                {displayOptions.displayMode === RestoHeaderDisplayModes.TITLE && (
                    <HeaderTitleContainer>
                        <HeaderTitleContainer>
                            <HeaderChildTitleContainer>
                                <HeaderChildTitle>
                                    {displayOptions.title}
                                </HeaderChildTitle>
                            </HeaderChildTitleContainer>
                        </HeaderTitleContainer>
                    </HeaderTitleContainer>
                )}
                {displayOptions.displayMode === RestoHeaderDisplayModes.SLIDER && (
                    <MenuCategoryHeader categories={menuCategories} />
                )}
            </RestaurantHeader>
            <Outlet />
        </RestoLayoutContext.Provider>
    )
}

export default RestoLayoutContext