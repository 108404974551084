const paymentHu = {
    "payment_summary": "Fizetési összefoglaló",
    "payments_empty": "Nincsen fizetésre kijelölt tételed",
    "payment_methods": "Fizetési módok",
    "payment_success": "Fizetés sikeres",
    "pay_button": "Fizetés",
    "payable": "Fizetendő",
    "payment_instructions": "A kiadott rendelések közül válaszd ki mi az, amit fizetsz",
    "pay_all": "Fizetés egyben",
    "no_payable": "Jelenleg nincsenek fizetendő rendeléseid" ,
    "successful_payment": "Sikeres fizetés",
    "price_per_piece": "Ft/db",
    "selected": "Kiválasztva:"
}

export default paymentHu;