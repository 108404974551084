import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { primary } from '../../styles/colors'

type Props = {}

const LoadingPage = (props: Props) => {
    return (
        <Box textAlign={"center"}>
            <CircularProgress style={{ color: primary, fontSize: "1.2rem", marginTop: "120px" }} size={60} />
        </Box>
    )
}

export default LoadingPage