import React, { createContext, useEffect, useState } from 'react'
import { RestaurantSetting } from '../model/RestaurantSetting';
import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import useFetch from '../hooks/useFetch';
import { getCurrentMenu, getRestaurantSettings } from '../config/apiRoutes';
import PageError from '../components/common/PageError';
import { MenuCategory, SerializedMenuCategory, deserializeMenuCategory } from '../model/MenuCategory';
import { MenuItem } from '../model/MenuItem';

interface RestaurantContextState {
    restaurant: RestaurantSetting | null;
    menuCategories: MenuCategory[];
    getMenuItemById: (id: number) => MenuItem | undefined;
}

const RestaurantContext = createContext<RestaurantContextState>(null as any);

export const RestaurantProvider = () => {
    const { t } = useTranslation();
    const [isRestaurantAvailable, setIsRestaurantAvailable] = useState<boolean>(true);
    const [restaurant, setRestaurant] = useState<RestaurantSetting | null>(null);
    const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
    const caller = useFetch();
    const params = useParams();

    useEffect(() => {
        requestRestaurantData();
    }, []);

    async function requestRestaurantData() {
        const options = {
            path: getRestaurantSettings.path.replace(":shortName", params.shortName as string),
            method: getRestaurantSettings.method
        }
        const response = await caller.call<undefined, RestaurantSetting>(options);
        if (response.ok) {
            const restoData = response.data as RestaurantSetting;
            setRestaurant(restoData);
            setIsRestaurantAvailable(restoData.isEnabled);
            requestMenu();
        } else {
            setIsRestaurantAvailable(false);
        }
    }

    async function requestMenu() {
        const options = {
            path: getCurrentMenu.path.replace(":shortName", params.shortName as string),
            method: getCurrentMenu.method
        }
        const response = await caller.call<undefined, SerializedMenuCategory[]>(options);
        if (response.ok && response.data) {
            setMenuCategories(response.data.map(deserializeMenuCategory));
        }
    }

    function getMenuItemById(id: number) {
        return menuCategories.flatMap(category => category.menuItems).find(item => item.id === id);
    }

    const state = {
        restaurant,
        menuCategories,
        getMenuItemById
    }

    return (
        <RestaurantContext.Provider value={state}>
            {restaurant != null && isRestaurantAvailable && (<Outlet />)}
            {!isRestaurantAvailable && (
                <PageError errorText={t("common.restaurant_not_found")} />
            )}
        </RestaurantContext.Provider>
    )
}

export default RestaurantContext