
export default function useScroll() {

    function getScrollTop() {
        return window.scrollY;;
    }

    function registerScrollListener(callback: (props: any) => any) {
        window.addEventListener("scroll", callback);
    }

    function removeScrollListener(callback: (props: any) => any){
        window.removeEventListener("scroll", callback);
    }

    function scrollToPosition(newPosition: number) {
        window.scrollTo({ top: newPosition, behavior: "smooth" });
    }

    return {
        getScrollTop,
        registerScrollListener,
        removeScrollListener,
        scrollToPosition
    }
}