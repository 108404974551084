import { MenuItem } from "./MenuItem";
import { Order, OrderStatus } from "./Order";
import { OrderItem } from "./OrderItem";

export interface AggregatedOrderItem {
    itemHash: string;
    unitPrice: number;
    sumPrice: number;
    quantity: number;
    pendingQuantity: number;
    payableQuantity: number;
    notes: string;
    menuItem: MenuItem;
    createdAt: Date;
    markedByMe: number;
    markedByOthers: number;
    payableByMe: number;
}

export function generateAggregatedOrderItems(orders: Order[], sessionId: string): AggregatedOrderItem[] {
    const aggregatedItems: AggregatedOrderItem[] = [];
    for (const order of orders) {
        for (const item of order.items) {
            addItemToAggregation(aggregatedItems, item, order, sessionId);
        }
    }
    return aggregatedItems;
}

function addItemToAggregation(aggregatedItems: AggregatedOrderItem[], item: OrderItem, order: Order, sessionId: string) {
    const existingItem = aggregatedItems.find(ai => ai.itemHash === item.itemHash);
    if (existingItem) {
        addExistingItemToAggregation(existingItem, item, order, sessionId);
    } else {
        addNewItemToAggregations(aggregatedItems, item, order, sessionId);
    }
}

function addExistingItemToAggregation(existingItem: AggregatedOrderItem, item: OrderItem, order: Order, sessionId: string) {
    existingItem.quantity++;
    existingItem.sumPrice += item.unitPrice;
    if (order.orderStatus === OrderStatus.PLACED || order.orderStatus === OrderStatus.ACCEPTED) {
        existingItem.pendingQuantity++;
    } else if (order.orderStatus === OrderStatus.SERVED) {
        existingItem.payableQuantity++;
    }
    if (item.isMarkedForPayment) {
        if (item.markedBy === sessionId) {
            existingItem.markedByMe++;
        } else {
            existingItem.markedByOthers++;
        }
    }
    existingItem.payableByMe = existingItem.payableQuantity - existingItem.markedByOthers;
}

function addNewItemToAggregations(aggregatedItems: AggregatedOrderItem[], item: OrderItem, order: Order, sessionId: string) {
    const newItem = {
        itemHash: item.itemHash,
        quantity: 1,
        sumPrice: item.unitPrice,
        unitPrice: item.unitPrice,
        notes: item.note,
        menuItem: item.menuItem!,
        createdAt: item.createdAt,
        pendingQuantity: order.orderStatus === OrderStatus.PLACED || order.orderStatus === OrderStatus.ACCEPTED ? 1 : 0,
        payableQuantity: order.orderStatus === OrderStatus.SERVED ? 1 : 0,
        markedByMe: item.isMarkedForPayment && item.markedBy === sessionId ? 1 : 0,
        markedByOthers: item.isMarkedForPayment && item.markedBy !== sessionId ? 1 : 0,
        payableByMe: 0
    };
    newItem.payableByMe = newItem.payableQuantity - newItem.markedByOthers;
    aggregatedItems.push(newItem);
}

export interface UserPaymentItem{
    itemHash: string;
    menuItem: MenuItem;
    unitPrice: number;
    quantity: number;
    sumPrice: number;
}