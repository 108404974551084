import React, { createContext, useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom';
import RestaurantContext from './RestaurantContext';
import { t } from 'i18next';
import PageError from '../components/common/PageError';
import { Table, defaultTable } from '../model/table';

type Props = {}

interface TableContextState {
    currentTable: Table;
}

const TableContext = createContext<TableContextState>(null as any);

export const TableProvider = (props: Props) => {
    const { restaurant } = useContext(RestaurantContext);
    const params = useParams();
    const [isTableAllowed, setIsTableAllowed] = React.useState<boolean>(checkIfTableIsAllowed());
    const [currentTable] = React.useState<Table>(restaurant?.tables.find(table => table.id === parseInt(params.table as string)) || defaultTable);
    
    useEffect(() => {
        setIsTableAllowed(checkIfTableIsAllowed());
    }, [restaurant]);

    function checkIfTableIsAllowed(){
        if (restaurant == null) {
            return false;
        } 
        //if ordering is disabled globally, then table is allowed
        if (!restaurant.isOrderingEnabled){
            return restaurant.isEnabled;
        } 
        const tableId = parseInt(params.table as string);
        const matchingTable = restaurant.tables.find(table => table.id === tableId);
        if (matchingTable == null) {
            return false;
        } 
        return matchingTable.isEnabled;
    }

    const state = {
        currentTable
    }

    return (
        <TableContext.Provider value={state}>
            {restaurant != null && isTableAllowed && (<Outlet />)}
            {restaurant != null && !isTableAllowed && (
                <PageError errorText={t("common.table_not_available")} />
            )}
        </TableContext.Provider>
    )
}

export default TableContext