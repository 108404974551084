import { useState } from "react";
import { ApiRoute } from "../config/apiRoutes";
import { error } from "console";

export interface ILoader {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    successMessage?: string;
}

export const defaultLoader: ILoader = {
    loading: false,
    error: false,
    errorMessage: "",
}

export interface FetchCallResponse<T> {
    status: number;
    ok: boolean;
    data?: T;
    parsed: boolean;
}

interface IErrorBody {
    statusCode: number;
    message: string | string[]
}

export default function useFetch() {
    const [loader, setLoader] = useState<ILoader>(defaultLoader);

    async function call<T, Q>(apiRoute: ApiRoute, body?: T): Promise<FetchCallResponse<Q>> {
        setLoader({ ...loader, loading: true, error: false });
        try {
            return await doFetch(apiRoute, body);
        } catch (e) {
            console.error(e);
        }
        return {
            status: 0,
            ok: false,
            parsed: false
        }
    }

    async function doFetch<T>(apiRoute: ApiRoute, body?: T) {
        const options = constructFetchOptions(apiRoute.method, body);
        const response = await fetch(apiRoute.path, options as RequestInit);
        if (response.ok) {
            return await processOkResponse(response);
        } else {
            return await processErrorResponse(response);
        }
    }

    async function processOkResponse(response: Response) {
        try {
            const data = await response.json();
            setLoader({
                loading: false,
                error: false,
                errorMessage: "",
            });
            return {
                status: response.status,
                ok: response.ok,
                data,
                parsed: true
            };
        } catch (e) {
            setLoader({
                loading: false,
                error: true,
                errorMessage: "Invalid response from server",
            });
            return {
                status: response.status,
                ok: response.ok,
                parsed: false
            };
        }
    }

    async function processErrorResponse(response: Response) {
        const errorMessage = await constructErrorMessage(response);
        setLoader({
            loading: false,
            error: true,
            errorMessage: errorMessage,
        });
        return {
            status: response.status,
            ok: response.ok,
            parsed: false
        };
    }

    return {
        ...loader,
        call
    }
}

export function constructFetchOptions(method: string, data?: any) {
    const options = {
        method: method,
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer'
    }
    if (method != "GET" && data != null) {
        (options as any)["body"] = JSON.stringify(data);
    }
    return options;
}

export async function constructErrorMessage(response: Response) {
    try {
        const json: IErrorBody = await response.json();
        const message = typeof json.message === "string" ? json.message : json.message.join(", ");
        return message;
    } catch (e) {
        return `[${response.status}]: an unknown error occured :'(`;
    }
}