import { SwipeableDrawer } from '@mui/material';
import React from 'react'

type Props = {
    children?: any;
    anchor?: "left" | "right" | "top" | "bottom";
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
}

const CustomDrawer = (props: Props) => {
    const anchor = props.anchor || "bottom";

    function handleOpen() {
        props.setOpen(true);
    }

    function handleClose() {
        props.setOpen(false);
    }

    return (
        <SwipeableDrawer
            anchor={anchor}
            open={props.isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            disableSwipeToOpen
        >
            {props.children}
        </SwipeableDrawer>
    )
}

export default CustomDrawer