const paymentEn = {
    "payment_summary": "Payment summary",
    "payments_empty": "You have no items marked for payment",
    "payment_methods": "Payment methods",
    "payment_success": "Payment successful",
    "pay_button": "Pay",
    "payable": "Payable",
    "payment_instructions": "Choose what you pay from among the issued orders",
    "pay_all": "Payment in one",
    "no_payable": "You do not have payable orders at the moment",
    "successful_payment": "Payment successful",
    "price_per_piece": "Ft per piece",
    "selected": "Selected:"
}

export default paymentEn;