import { MenuItem, SerializedMenuItem, deserializeMenuItem } from "./MenuItem";
import { MenuCategoryTranslation } from "./Translation";

export interface SerializedMenuCategory {
    id: number;
    restaurantShortName: string;
    isActive: boolean;
    translations: MenuCategoryTranslation[];
    menuItems: SerializedMenuItem[];
    order: number;
}

export interface MenuCategory {
    id: number;
    restaurantShortName: string;
    isActive: boolean;
    translations: MenuCategoryTranslation[];
    menuItems: MenuItem[];
    order: number;
}

export function deserializeMenuCategory(serialized: SerializedMenuCategory): MenuCategory {
    return {
        id: serialized.id,
        restaurantShortName: serialized.restaurantShortName,
        isActive: serialized.isActive,
        translations: serialized.translations,
        menuItems: serialized.menuItems.map(deserializeMenuItem),
        order: serialized.order,
    };
}