const orderEn = {
    "total": "Total",
    "order_successfully_placed": "Order placed",
    "empty_cart": "You cart is currently empty",
    "place_order": "Place order",
    "i_order": "Place order",
    "order_confirmation": "Order confirmation",
    "order_confirmation_msg_raw": 'By placing your order, you accept our terms of use and our privacy policy, which you can find in the <a href=":link" class="generated-link" target="_blank">link below</a>.',
    "status_PLACED": "Placed",
    "status_ACCEPTED": "Accepted",
    "status_SERVED": "Served",
    "status_PAID": "Paid",
    "empty_pending_orders": "You have no pending orders at the moment",
    "overview": "Order overview",
    "note": "Note",
    "items": "Items",
    "review_order": "Overview",
}

 export default orderEn;