import { useTranslation } from "react-i18next";

interface Translation {
    id: number;
    title: string;
    description: string;
    isoCode: string;
}

export const defaultTranslation: Translation = {
    id: 0,
    title: "_title",
    description: "_description",
    isoCode: "unknown"
}

export interface MenuCategoryTranslation extends Translation { }
export interface MenuItemTranslation extends Translation { }

export function useTranslationSearch<T extends Translation>(translations: T[], fallback = "en") {
    const { i18n } = useTranslation();

    function findRelevantTranslation() {
        const matchingTranslation = translations.find(translation => translation.isoCode === i18n.language);
        if (matchingTranslation) return matchingTranslation;
        const fallbackTranslation = translations.find(translation => translation.isoCode === fallback);
        if (fallbackTranslation) return fallbackTranslation;
        if (translations.length > 0) {
            console.warn(`No translation found for ${i18n.language} or fallback (${fallback}). Using the first translation.`);
            return translations[0];
        }
        console.warn(`No translation found for ${i18n.language} or fallback (${fallback}).`);
        return defaultTranslation;
    }

    return findRelevantTranslation();
}