import React, { useContext } from 'react'
import { MenuCategory } from '../../model/MenuCategory';
import { useTranslationSearch } from '../../model/Translation';
import { MenuCategorySliderButton, MenuCategorySliderItem } from '../../styles/menuStyles';
import { MenuCategoryContext } from '../../contexts/MenuCategoryContext';
import { ThemeProvider } from 'styled-components';


type Props = {
    category: MenuCategory;
}

const MenuCategoryHeaderColumn = (props: Props) => {
    const categoryTexts = useTranslationSearch(props.category.translations);
    const { selectedCategoryId, selectCategoryManually, generateHeaderJSXId } = useContext(MenuCategoryContext);
    const isSelected = selectedCategoryId === props.category.id;
    
    function handleContainerClick() {
        selectCategoryManually(props.category.id);
    }

    return (
        <MenuCategorySliderItem
            id={generateHeaderJSXId(props.category.id)}
            onClick={handleContainerClick}
        >
            <ThemeProvider theme={{ isSelected: isSelected }}>
                <MenuCategorySliderButton>
                    {categoryTexts.title}
                </MenuCategorySliderButton>
            </ThemeProvider>
        </MenuCategorySliderItem>
    )
}

export default MenuCategoryHeaderColumn