export const reservedRestaurantHeaderHeight =  156;

export const allowedLangOptions = [
    { value: "hu", label: "Magyar", iso: "HU" },
    { value: "en", label: "English", iso: "EN" },
]

export const minHeaderHeight = 88;
export const maxHeaderHeight = reservedRestaurantHeaderHeight;
const minPaddingTop = 8;
const maxPaddingTop = 56;

export function getHeaderHeight(y: number) {
    const newHeight = maxHeaderHeight - y / 2;
    if (newHeight < minHeaderHeight) {
        return minHeaderHeight;
    }
    return newHeight;
}

export function getHeaderPaddingTop(y: number) {
    const newHeight = maxPaddingTop - y / 2;
    if (newHeight < minPaddingTop) {
        return minPaddingTop;
    }
    return newHeight;
}