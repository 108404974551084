 const commonEn = {
    "restaurant": "Restaurant",
    "restaurant_not_found": "The restaurant is not available",
    "table_not_available": "Orders cannot be placed at this table",
    "note": "Note",
    "order": "Order",
    "internal_error": "Request unsuccessful. The server responded with the following error: ",
    "no_results": "No results",
    "cancel": "Cancel",
    "save": "Save",
    "back": "Back",
    "terms_placeholder": "The text of our terms of use will appear here."
 }

 export default commonEn;