const orderHu = {
    "total": "Összesen",
    "order_successfully_placed": "Rendelés leadva",
    "empty_cart": "Jelenleg nincs tétel a rendelésedben",
    "place_order": "Megrendelem",
    "i_order": "Rendelek",
    "order_confirmation": "Rendelés megerősítése",
    "order_confirmation_msg_raw": 'A rendelésed leadásával elfogadod a felhasználási feltételeinket és az adatvédelmi szabályzatunkat, melyeket az <a href=":link" class="generated-link" target="_blank">alábbi linken</a> találsz.',
    "status_PLACED": "Leadva",
    "status_ACCEPTED": "Elfogadva",
    "status_SERVED": "Kiszolgálva",
    "status_PAID": "Fizetve",
    "empty_pending_orders": "Jelenleg nincs függőben lévő rendelésed",
    "overview": "Rendelés áttekintése",
    "note": "Megjegyzés",
    "items": "Tételek",
    "review_order": "Áttekintés",
}

export default orderHu;