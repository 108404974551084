import { Button, IconButton, styled as muiStyled } from "@mui/material";
import { altText, disabledDark, disabledLight, hexToRgbA, primary, secondaryText } from "./colors";
import styled from "styled-components";

export const RButton = muiStyled(Button)`
    background: linear-gradient(90deg, ${primary} 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(90deg, ${primary} 0%, ${secondaryText} 100%);
    text-transform: none;
    color: ${altText};
    font-size: 1rem;
    border-radius: 10px;
    &:disabled{
        background: ${disabledLight};
        color: ${altText};
    }
`;

export const RIconButton = muiStyled(IconButton)`
    background: linear-gradient(to left, ${secondaryText}, ${primary});
    color: ${altText};
    padding: 0.3rem;
    &:disabled{
        background: ${disabledLight} !important;
        color: ${altText} !important;
    }
`;

export const RCancelButton = muiStyled(Button)`
    background: linear-gradient(90deg, ${disabledLight} 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(90deg, ${secondaryText} 0%, ${disabledDark} 100%);
    text-transform: none;
    color: ${altText};
    font-size: 1rem;
    border-radius: 10px;
`;

export const TransparentIconButton = muiStyled(IconButton)`
    background: transparent;
    padding: 0.3rem !important;
    svg{
        color: ${altText};
    }
    &:disabled{
        svg{
            color: rgba(255, 255, 255, 0.5);
        }
    }
`;

export const GButton = muiStyled(Button)`
    background: linear-gradient(90deg, ${primary} 0%, ${secondaryText} 80%, ${secondaryText} 100%);
    box-shadow: 0px 2px 14px 0px #00000059;
    border-radius: 12px;
    text-transform: none;
    color: ${altText};
    font-size: 1rem;
    padding: 0.6rem 0.8rem;
    font-weight: 500;
    transition: background 0.2s linear;
    transition: color 0.5s linear;
    &:disabled{
        background: linear-gradient(90deg, ${disabledLight} 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(90deg, ${disabledDark} 0%, ${disabledDark} 100%);
        color: ${altText};
    }
` as typeof Button;

export const FlatIconButton = muiStyled(IconButton)`
    background: ${secondaryText};
    border-radius: 7px;
    padding: 0.6rem;
    color: ${altText};
    box-shadow: 0px 2px 14px 0px #00000059;
    &:hover{
        background: ${primary};
    }
`;

export const PrimaryIconButton = muiStyled(IconButton)`
    background: ${primary};
    border-radius: 7px;
    padding: 0.6rem;
    color: ${altText};
    box-shadow: 0px 2px 14px 0px #00000059;
    &:hover{
        background: ${secondaryText};
    }
`;

export const PrimaryButton = muiStyled(Button)`
    background: ${primary};
    text-transform: none;
    font-size: 1rem;
    color: ${altText};
    box-shadow: 0 0 2px 2px ${hexToRgbA(primary, 0.4)};
    &:active{
        background: ${secondaryText};
    }
    &:focus{
        background: ${secondaryText};
    }
    &:hover{
        background: ${secondaryText};
    }
`;

export const UnstyledButton = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    text-align: left;
    width: 100%;
`;