
export const primary = "#70AA7A";
export const innerBackground = "#F7F2F2";
export const primaryBackground = "#fff";
export const hightlightBackground = "#ECF4EE";
export const lightBackground = "#F5F0F3";
export const secondaryText = "#3E635C";
export const primaryBorder = "#AAAAAA"
export const altText = "#FFF";
export const captionColor = "#858585";
export const disabledLight = "#A1A1A1";
export const disabledDark = "#676767";
export const disabledControl = "#646464";

//export const secondary = "#087E8B";
export const primaryText = "#000";
export const success = "#087E8B";
export const error = "#D21F3C";


export function hexToRgbA(hex: string, opacity = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        //@ts-ignore
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
    }
    throw new Error('Bad Hex');
}
