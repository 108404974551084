
export interface ApiRoute {
    path: string;
    method: string;
}

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getCurrentMenu: ApiRoute = {
    path: baseURL + '/menu/current/:shortName',
    method: 'GET',
}

export const getRestaurantSettings: ApiRoute = {
    path: baseURL + '/restaurant/one/:shortName',
    method: 'GET',
}

export const putPlaceOrder: ApiRoute = {
    path: baseURL + '/order/place-order',
    method: 'PUT',
}

export const getOpenOrders: ApiRoute = {
    path: baseURL + '/order/open-orders/:tableId',
    method: 'GET',
}

export const getSessionId: ApiRoute = {
    path: baseURL + '/order/uuid',
    method: 'GET',
}