import React from 'react'

type Props = {
    children: any;
}

interface NotificaitonContextState {
    updateSignal: (newSignal: NotificationSignal) => void;
    getSignal: (key: string) => NotificationSignal | undefined;
    removeSignal: (key: string) => void;
}

export interface NotificationSignal {
    message: string;
    type: "success" | "error" | "info";
    key: string;
}

const NotificationContext = React.createContext<NotificaitonContextState>(null as any);

export const NotificationProvider = (props: Props) => {
    const [notifications, setNotifications] = React.useState<NotificationSignal[]>([]);

    function updateSignal(newSignal: NotificationSignal){
        const existing = notifications.find(n => n.key === newSignal.key);
        if(existing == null){
            setNotifications([...notifications, newSignal]);
        } else {
            setNotifications([...notifications.filter(n => n.key !== newSignal.key), newSignal]);
        }
    }

    function getSignal(key: string){
        return notifications.find(n => n.key === key);
    }

    function removeSignal(key: string){
        setNotifications([...notifications.filter(n => n.key !== key)]);
    }

    const state = {
        updateSignal,
        getSignal,
        removeSignal
    }

    return (
        <NotificationContext.Provider value={state}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationContext