import React, { useContext, useEffect } from 'react'
import { MenuCategory } from '../../model/MenuCategory'
import MenuCategoryHeaderColumn from './MenuCategoryHeaderColumn';
import { MenuCategorySliderContainer, MenuOpenCategoryButton, MenuSliderGrid, MenuSliderIconContainer } from '../../styles/menuStyles';
import { MenuCategoryContext } from '../../contexts/MenuCategoryContext';
import CustomDrawer from '../common/CustomDrawer';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { t } from 'i18next';
import MenuListSearch from './MenuListSearch';
import { secondaryText } from '../../styles/colors';
import MenuIcon from '@mui/icons-material/Menu';

type Props = {
  categories: MenuCategory[];
}

const MenuCategoryHeader = (props: Props) => {
  const { selectedCategoryId, generateHeaderJSXId, selectCategoryManually } = useContext(MenuCategoryContext);
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const [sidePanelOpen, setSidePanelOpen] = React.useState(false);

  useEffect(() => {
    if (selectedCategoryId == null) return;
    const headerElement = sliderRef.current;
    if (headerElement == null) return;
    const selectedCategoryElement = document.getElementById(generateHeaderJSXId(selectedCategoryId));
    if (selectedCategoryElement == null) return;
    const selectedCategoryElementOffsetLeft = selectedCategoryElement.offsetLeft;
    const centerOfColumn = selectedCategoryElementOffsetLeft + selectedCategoryElement.offsetWidth / 2;
    headerElement.scrollTo({ left: centerOfColumn - (window.innerWidth / 2), behavior: "smooth" });
  }, [selectedCategoryId]);

  function handleCategoryClick(categoryId: number) {
    setSidePanelOpen(false);
    selectCategoryManually(categoryId);
  }

  return (
    <>
      <MenuSliderGrid>
        {props.categories.length > 4 && (
          <MenuSliderIconContainer>
            <Box mr={"0.6rem"} ml={"0.6rem"} pt={"3px"}>
              <MenuOpenCategoryButton onClick={() => setSidePanelOpen(true)}>
                <MenuIcon />
              </MenuOpenCategoryButton>
            </Box>
          </MenuSliderIconContainer>
        )}
        <MenuCategorySliderContainer ref={sliderRef}>
          {props.categories.map((category) => {
            return <MenuCategoryHeaderColumn key={category.id} category={category} />
          })}
        </MenuCategorySliderContainer>
      </MenuSliderGrid>
      <CustomDrawer
        isOpen={sidePanelOpen}
        setOpen={setSidePanelOpen}
        anchor='left'
      >
        <Divider />
        <Box style={{ width: window.innerWidth * 0.8 }}>
          <Grid container>
            <Grid item xs>
              <Typography px={1.5} fontSize={"1rem"} color={secondaryText} fontWeight={500} py={1}>
                {t("menu.available_categories")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <MenuListSearch categories={props.categories} handleCategoryClick={handleCategoryClick} />
        </Box>
      </CustomDrawer>
    </>
  )
}

export default MenuCategoryHeader;