import commonEn from "./commonEn";
import menuEn from "./menuEn";
import navigationEn from "./navigationEn";
import orderEn from "./orderEn";
import paymentEn from "./paymentEn";

const mainEn = {
    common: commonEn,
    navigation: navigationEn,
    menu: menuEn,
    order: orderEn,
    payment: paymentEn
}

export default mainEn;