import { MenuItem, SerializedMenuItem, deserializeMenuItem } from "./MenuItem";

export interface OrderItem {
    id: number;
    orderId: number;
    itemHash: string;
    menuItemId: number;
    note: string;
    isMarkedForPayment: boolean;
    markedBy: string;
    isPaid: boolean;
    unitPrice: number;
    createdAt: Date;
    menuItem?: MenuItem;
}

export interface SerializedOrderItem {
    id: number;
    orderId: number;
    menuItemId: number;
    itemHash: string;
    note: string;
    isPaid: boolean;
    isMarkedForPayment: boolean;
    markedBy: string;
    unitPrice: string;
    createdAt: string;
    menuItem?: SerializedMenuItem;
}

export function deserializeOrderItem(item: SerializedOrderItem): OrderItem {
    return {
        ...item,
        createdAt: new Date(item.createdAt),
        unitPrice: parseFloat(item.unitPrice),
        menuItem: item.menuItem ? deserializeMenuItem(item.menuItem) : undefined
    }
}

interface GenerateOrderItemhashOptions {
    notes: string;
}

export function generateOrderItemHash(menuItem: MenuItem, options: GenerateOrderItemhashOptions) {
    return `${menuItem.id}-${menuItem.priceGross}-${options.notes}`;
}