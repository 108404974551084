import { Box, InputAdornment, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { RStandardTextField } from '../../styles/controls'
import MenuCategoryListItem from './MenuCategoryListItem'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { removeAccentsAndLowerCase } from '../../helpers/textHelper';
import { MenuCategory } from '../../model/MenuCategory';
import { useTranslation } from 'react-i18next';


type Props = {
    categories: MenuCategory[];
    handleCategoryClick: (categoryId: number) => void;
}

const MenuListSearch = (props: Props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const searchedCategories = getSearchedCategories();

    function getSearchedCategories() {
        const term = removeAccentsAndLowerCase(search);
        return props.categories.filter(category => isCategoryResult(category, term));

    }

    function isCategoryResult(category: MenuCategory, searchTerm: string) {
        const titleMatch = category.translations
            .flatMap(t => t.title)
            .some(t => removeAccentsAndLowerCase(t).includes(searchTerm));
        if (titleMatch) return true;

        return category.menuItems
            .flatMap(item => item.translations)
            .some(translation => removeAccentsAndLowerCase(translation.title).includes(searchTerm));
    }

    return (
        <>
            {props.categories.length > 4 && (
                <Box p={1} pt={1.5}>
                    <RStandardTextField
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t("menu.search_menu_placeholder")}
                        fullWidth
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (<>
                                {search.length > 0 && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setSearch("")}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>)
                        }}
                    />
                </Box>
            )}

            {searchedCategories.length === 0 && (<Box>
                <Typography p={1} textAlign="center" fontStyle="italic">
                    {t("common.no_results")}
                </Typography>
            </Box>)}
            <Box>
                {searchedCategories.map((category, i) => {
                    return <MenuCategoryListItem
                        key={category.id}
                        category={category}
                        onClick={() => props.handleCategoryClick(category.id)}
                        showDivider={searchedCategories.length - 1 !== i}
                    />
                })}
            </Box>
        </ >
    )
}

export default MenuListSearch