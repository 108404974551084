const navigationEn = {
    "menu": "Menu",
    "my_orders": "Orders",
    "my_table": "My Table",
    "table/all": "All",
    "table/pending": "Pending",
    "payment": "Payment",
    "terms_and_conditions": "Terms and conditions",
    "back_to_orders": "Close page"
};

export default navigationEn;