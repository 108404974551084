import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import mainEn from "./localization/en/mainEn";
import mainHu from "./localization/hu/mainHu";

const resources = {
  en: {
    translation: mainEn
  },
  hu: {
    translation: mainHu
  },
  fallbackLang: {
    "hu": ["en"]
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;