import { MenuCategory } from '../../model/MenuCategory'
import { Box, Divider, Typography } from '@mui/material';
import { useTranslationSearch } from '../../model/Translation';

type Props = {
    category: MenuCategory;
    onClick: () => void;
    showDivider?: boolean;
}

const MenuCategoryListItem = (props: Props) => {
    const texts = useTranslationSearch(props.category.translations);
    return (
        <Box onClick={props.onClick}>
            <Typography py={1.3} px={2} fontSize={"0.875rem"}>
                {texts.title}
            </Typography>
            {props.showDivider && (
                <Divider />
            )}
        </Box>
    )
}

export default MenuCategoryListItem