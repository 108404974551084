import { useEffect, useMemo, useState } from "react";
import { OrderStatus, SerializedStoredOrder, StoredOrder, deserializeStoredOrder } from "../model/Order";
import { AggregatedOrderItem, generateAggregatedOrderItems } from "../model/AggregatedOrderItem";
import { SessionInfo } from "../contexts/SessionContext";
import { Table } from "../model/table";
import { sortByCreation } from "../model/Base";
import { OrderItem, generateOrderItemHash } from "../model/OrderItem";
import { MenuItem } from "../model/MenuItem";

const storePrefix = "cart-";
const defaultRetentionTime = 1000 * 60 * 60 * 3; //3 hours

export interface UseLocalCartHook {
    cart: StoredOrder | null;
    aggregatedCartOrderItems: AggregatedOrderItem[];
    getStoredCart: () => StoredOrder | null;
    addItemToCart: (menuItem: MenuItem, notes: string, quantity: number) => void;
    updateItemInCart: (newOrderItem: OrderItem, oldHash: string, newCount: number) => void;
    deleteCart: () => void;
    clearCart: () => void;
    updateCartNote: (newNote: string) => void;
}

export default function useLocalCart(session: SessionInfo, currentTable: Table) {
    const [cart, setCart] = useState<StoredOrder | null>(null);
    const aggregatedCartOrderItems = useMemo(() => {
        if (cart == null || cart.items.length === 0) {
            return [];
        }
        return generateAggregatedOrderItems([cart], session.sessionId)

    }, [cart, session]);

    useEffect(() => {
        setCart(getStoredCart());
    }, [])

    function getStoredCart() {
        const storedCart = localStorage.getItem(storePrefix + currentTable.id);
        if (storedCart == null) {
            return null;
        }
        try {
            const storedData: SerializedStoredOrder = JSON.parse(storedCart);
            const currentTime = new Date().getTime();
            if (currentTime - storedData.createdAt > storedData.retentionTime) {
                localStorage.removeItem(storePrefix + currentTable.id);
                return null;
            }
            return deserializeStoredOrder(storedData, session.sessionId);
        } catch (e) {
            console.log(e)
            return null;
        }
    }

    function addItemToCart(menuItem: MenuItem, notes: string, quantity: number) {
        const item: OrderItem = {
            id: 0,
            orderId: 0,
            menuItemId: menuItem.id,
            menuItem: menuItem,
            note: notes,
            unitPrice: menuItem.priceGross,
            isPaid: false,
            itemHash: generateOrderItemHash(menuItem, { notes }),
            isMarkedForPayment: false,
            markedBy: '',
            createdAt: new Date()
        };
        handleCartItemAdd(item, quantity);
    }

    function createNewCart(newItem: OrderItem, note: string, count: number) {
        const newCart: StoredOrder = {
            id: 0,
            tableId: currentTable.id,
            items: [],
            price: 0,
            orderStatus: OrderStatus.PENDING,
            createdAt: new Date().getTime(),
            retentionTime: defaultRetentionTime,
            code: "",
            note,
            aggregatedOrderitems: [],
        }
        for (let i = 0; i < count; i++) {
            newCart.items.push(newItem)
        }
        const aggregatedItems = generateAggregatedOrderItems([newCart], session.sessionId);
        aggregatedItems.sort(sortByCreation)
        saveToStorage({
            ...newCart,
            price: calculateTotalGrossPrice(newCart),
            aggregatedOrderitems: aggregatedItems
        });
    }

    function handleCartItemAdd(newItem: OrderItem, count: number) {
        if (cart == null) return createNewCart(newItem, "", count);
        const newCart = {
            ...cart,
            createdAt: new Date().getTime(),
        }
        for (let i = 0; i < count; i++) {
            newCart.items.push(newItem)
        }
        const aggregatedItems = generateAggregatedOrderItems([newCart], session.sessionId);
        aggregatedItems.sort(sortByCreation)
        saveToStorage({
            ...newCart,
            price: calculateTotalGrossPrice(newCart),
            aggregatedOrderitems: aggregatedItems
        });
    }

    function updateItemInCart(newOrderItem: OrderItem, oldHash: string, newCount: number) {
        if (cart == null) return;
        const newItems = cart.items.filter(item => item.itemHash !== oldHash);

        for (let i = 0; i < newCount; i++) {
            newItems.push(newOrderItem);
        }
        if (newItems.length === 0) {
            return deleteCart();
        }
        const newCart = {
            ...cart,
            items: newItems,
        }
        const aggregatedItems = generateAggregatedOrderItems([newCart], session.sessionId);
        aggregatedItems.sort(sortByCreation);
        saveToStorage({
            ...newCart,
            price: calculateTotalGrossPrice(newCart),
            aggregatedOrderitems: aggregatedItems
        });
    }

    function updateCartNote(newNote: string) {
        if (cart == null) return;

        saveToStorage({
            ...cart,
            createdAt: new Date().getTime(),
            note: newNote,
        });
    }

    function saveToStorage(newCart: StoredOrder) {
        localStorage.setItem(storePrefix + currentTable.id, JSON.stringify(newCart));
        setCart(newCart);
    }

    function deleteCart() {
        localStorage.removeItem(storePrefix + currentTable.id);
        setCart(null);
    }

    function calculateTotalGrossPrice(newCart: StoredOrder) {
        return newCart.items.reduce((acc, item) => {
            return item.unitPrice + acc;
        }, 0);
    }

    function clearCart() {
        localStorage.removeItem(storePrefix + currentTable.id);
        setCart(null);
    }

    return {
        cart,
        aggregatedCartOrderItems,
        getStoredCart,
        addItemToCart,
        updateItemInCart,
        deleteCart,
        updateCartNote,
        clearCart,

    }
}