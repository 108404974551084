import { FormControl, Select, Typography, MenuItem, Grid, InputBase, styled, IconButton, Menu } from '@mui/material'
import { allowedLangOptions } from '../../config/header'
import { useTranslation } from 'react-i18next'
import { usePathReconstructor } from '../../config/pageRoutes'
import TranslateIcon from '@mui/icons-material/Translate';
import React from 'react'
import { primary, secondaryText } from '../../styles/colors';

const LangSelector = () => {
    const { replaceParam } = usePathReconstructor();
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleLanguageSelection(newValue: string) {
        i18n.changeLanguage(newValue);
        syncURL(newValue);
        handleClose();
    };

    const selectedOption = getSelectedOption();

    function getSelectedOption() {
        const selectedLang = allowedLangOptions.find(lang => lang.value === i18n.language);
        if (selectedLang == null) return allowedLangOptions[0];
        return selectedLang;
    }

    function syncURL(newLang: string) {
        const newURL = replaceParam("lang", newLang);
        if (newURL != null) {
            window.history.replaceState(null, document.title, newURL);
        }
    }

    function isItemSelected(option: { value: string }) {
        return option.value === selectedOption.value;
    }

    return (
        <>
            <IconButton
                aria-controls={open ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <TranslateIcon style={{ color: secondaryText, fontSize: "1.5rem" }} />
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                id="language-menu"
                anchorEl={anchorEl}

            >
                {allowedLangOptions.map((lang) => (
                    <MenuItem
                        key={lang.value}
                        onClick={() => handleLanguageSelection(lang.value)}
                        selected={isItemSelected(lang)}
                    >
                        <Grid container columnSpacing={2}>
                            <Grid item>
                                <Typography fontWeight="bold">
                                    {lang.iso}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {lang.label}
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Menu>


        </>)
}

export default LangSelector