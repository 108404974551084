import { matchPath, useLocation, useParams } from "react-router-dom"

export const appName = "QuickPick";

export const menuPageRoute = "/menu/:shortName/:table/:lang";
export const orderPageRoute = "/order/:shortName/:table/:lang";
export const tablePageRoute = "/table/:shortName/:table/:lang";
export const paymentPageRoute = "/pay/:shortName/:table/:lang";
export const successfulPaymentPageRoute = "/payment-success/:shortName/:table/:lang";
export const termsAndConditionsPageRoute = "/terms-and-conditions/:shortName/:table/:lang";

export const liveRoutes = [
    menuPageRoute,
    orderPageRoute,
    tablePageRoute,
    successfulPaymentPageRoute,
    termsAndConditionsPageRoute
];

export function findMatchingRoute(path: string) {
    return liveRoutes.find(route => matchPath(route, path) != null);
}

export function usePathReconstructor() {
    const params = useParams();
    const location = useLocation();

    function replaceParam(propName: string, value: string) {
        let resultPath = findMatchingRoute(location.pathname);
        if (resultPath == null) return null;;
        for (const key in params) {
            if (key === propName) {
                resultPath = resultPath.replace(`:${key}`, value);
            } else if (params[key] != null) {
                resultPath = resultPath.replace(`:${key}`, params[key] as string);
            }
        }
        return resultPath;
    }

    function copyParamsFromPath(targetPath: string) {
        let resultPath = targetPath;
        for (const key in params) {
            resultPath = resultPath.replace(`:${key}`, params[key] as string);
        }
        return resultPath;
    }

    return {
        replaceParam,
        copyParamsFromPath,
    }
}