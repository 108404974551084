export interface SerializedVatkey{
    code: string;
    valuePercent: string;
}

export interface VatKey {
    code: string;
    valuePercent: number;
}

export function deserializeVatKey(serialized: SerializedVatkey): VatKey {
    return {
        code: serialized.code,
        valuePercent: parseFloat(serialized.valuePercent),
    };
}