import React, { useContext, useEffect, useRef, useState } from 'react'
import useFetch from '../hooks/useFetch';
import { getSessionId } from '../config/apiRoutes';
import SnackbarContext from './SnackbarContext';
import RestaurantContext from './RestaurantContext';
import { Outlet, useLocation } from 'react-router-dom';

type ProviderProps = {
}

interface SessionContextProps {
    session: SessionInfo;
    locationStack: React.MutableRefObject<string[]>
}

const SessionContext = React.createContext<SessionContextProps>({} as any)

export interface SessionInfo {
    sessionId: string;
    isLoaded: boolean;
    isActive: boolean;
}

const defaultSessionInfo: SessionInfo = { sessionId: "", isLoaded: false, isActive: false };

export const SessionProvider = (props: ProviderProps) => {
    const { restaurant } = useContext(RestaurantContext);
    const [session, setSession] = useState(defaultSessionInfo);
    const { showMessage } = useContext(SnackbarContext);
    const sessionCaller = useFetch();
    const location = useLocation();
    const locationStack = useRef<string[]>([]);

 
    useEffect(() => {
        if (restaurant != null && restaurant.isOrderingEnabled === true) {
            initiateSessionId();
        } else {
            setSession({
                sessionId: "",
                isLoaded: true,
                isActive: false
            });
        }
    }, [restaurant]);

    useEffect(()=>{
        locationStack.current.push(location.key);
    }, [location.key]);

    function initiateSessionId() {
        const storedSessionId = localStorage.getItem("sessionId");
        if (storedSessionId) {
            setSession({
                sessionId: storedSessionId,
                isLoaded: true,
                isActive: true
            });
        } else {
            generateSessionId();
        }
    }

    async function generateSessionId() {
        const response = await sessionCaller.call<undefined, { uuid: string }>(getSessionId);
        if (response.ok) {
            localStorage.setItem("sessionId", response.data?.uuid as string);
            setSession({
                sessionId: response.data?.uuid as string,
                isLoaded: true,
                isActive: true
            });
        } else {
            showMessage("Fatal error: failed to iniate ordering process", "error");
        }
    }

    const state = {
        session,
        locationStack
    }

    return (
        <SessionContext.Provider value={state}>
            {session.isLoaded && (<Outlet />)}
        </SessionContext.Provider>
    )
}

export default SessionContext;