import { Box, Checkbox, Radio, TextField, styled } from "@mui/material";
import { disabledControl, disabledLight, hexToRgbA, primary, secondaryText } from "./colors";

export const RStandardTextField = styled(TextField)`
    /* default */
    .MuiInput-underline:before {
        border-bottom: 1px solid ${primary};
    }
    /* hover (double-ampersand needed for specificity reasons. */
    && .MuiInput-underline:hover:before {
        border-bottom: 2px solid ${primary};
    }
    /* focused */
    .MuiInput-underline:after {
        border-bottom: 2px solid ${primary};
    }
`;

export const RContainedTextField = styled(TextField)`
    & label.Mui-focused {
        color:  ${primary};
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
        border-color: ${primary};
        }
    }
`;

export const RRadio = styled(Radio)`
    &.Mui-checked{
        color: ${primary};
    }
    width: 1rem;
    &.MuiSvgIcon-root{
        height: 1rem;
        width: 1rem;
    }
    transform: scale(0.8);
`;

export const CounterContainer = styled(Box)`
    background: linear-gradient(to left, ${secondaryText}, ${primary});
    border-radius: 1.5em;
    -webkit-transition: width 0.15s ease;
    -moz-transition: width 0.15s ease;
    -o-transition: width 0.15s ease;
    transition: width 0.15s ease;
    will-change: width;
    box-shadow: 2px 1px 5px 0.7px ${hexToRgbA(disabledLight, 0.3)};
    .fade{
        animation: menuCounterFadeIn linear 0.3s;
        animation-iteration-count: 1;
    }
`;

export const LargeCounterContainer = styled(Box)`
    background: linear-gradient(to left, ${secondaryText}, ${primary});
    border-radius: 3em;    
`;

export const RCheckbox = styled(Checkbox)`
    &.Mui-checked{
        color: ${primary};
    }
    color:${disabledControl};
    height: 1rem;
    width: 1rem;
`;

export const StrongCheckbox = styled(Checkbox)`
    &.Mui-checked{
        color: ${primary};
    }
    color: black;
    height: 1.1rem;
    width: 1.1rem;
    transform: scale(1.1);
`;