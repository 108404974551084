import React, { createContext, useContext, useEffect, useRef } from 'react'
import useScroll from '../hooks/useScroll';
import { Outlet } from 'react-router-dom';
import RestaurantContext from './RestaurantContext';
import { maxHeaderHeight, minHeaderHeight } from '../config/header';

const menuIdPrefix = "menu-category-";
const headerIdPrefix = "menu-category-header-";

interface MenuCategoryContextState {
    selectedCategoryId: number | null;
    selectCategoryManually: (categoryId: number) => void;
    generateMenuJSXId: (categoryId: number) => string;
    generateHeaderJSXId: (categoryId: number) => string;
    activate: () => void;
    deactivate: () => void;
}

type Props = {

}

export const MenuCategoryContext = createContext<MenuCategoryContextState>(null as any);

const rootId = "root";

const MenuCategoryProvider = (props: Props) => {
    const { menuCategories } = useContext(RestaurantContext);
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<number | null>(null);
    const isAutoSelectionEnabled = useRef(true);
    const timeoutId = useRef<NodeJS.Timeout>();
    const categoryIds = useRef<number[]>(menuCategories.map((category) => category.id));
    const { registerScrollListener, removeScrollListener, getScrollTop, scrollToPosition } = useScroll();

    useEffect(() => {
        categoryIds.current = menuCategories.map((category) => category.id);
    }, [menuCategories]);

    function activate() {
        if(menuCategories.length > 0) {
            onScroll();
        }
        registerScrollListener(onScroll);
    }

    function deactivate() {
        removeScrollListener(onScroll);
        if (menuCategories.length > 0) {
            setSelectedCategoryId(menuCategories[0].id);
        } else {
            setSelectedCategoryId(null);
        }
    }

    function onScroll() {
        if (isAutoSelectionEnabled.current === false) return;
        const containerScrollTop = getScrollTop();
        const correctedScrollTop = containerScrollTop;// + 10; //we don't want to select the category when we can only see the bottom few pixels of it
        const categoryElementsWithOffset = findCategoryElements();
        const categoryElementsWithOffsetSorted = categoryElementsWithOffset.sort((a, b) => a.offsetTop - b.offsetTop);
        // const containerHeight = element.clientHeight;
        //const correction = 10; //containerHeight / 8; //we don't want to select the category when we can only see the bottom few pixels of it
        const categoryElementsWithOffsetSortedFiltered = categoryElementsWithOffsetSorted.filter((categoryElement) => categoryElement.offsetTop < correctedScrollTop);
        const lastCategoryElementWithOffset = categoryElementsWithOffsetSortedFiltered[categoryElementsWithOffsetSortedFiltered.length - 1];
        if (lastCategoryElementWithOffset) {
            setSelectedCategoryId(parseInt(lastCategoryElementWithOffset.id.replace(menuIdPrefix, "")));
        }
    }

    function selectCategoryManually(categoryId: number) {
        isAutoSelectionEnabled.current = false;
        clearTimeout(timeoutId.current!);
        timeoutId.current = setTimeout(() => {
            isAutoSelectionEnabled.current = true;
        }, 1000);
        scrollToCategoryInMenu(categoryId);
    }

    function scrollToCategoryInMenu(categoryId: number) {
        const categoryElement = document.getElementById(generateMenuJSXId(categoryId));
        if (categoryElement) {
            setSelectedCategoryId(categoryId);
            isAutoSelectionEnabled.current = false; //we don't want to select the category when we manually click on it, to avoid unnecessary scrolling and its effects
            scrollToPosition(getTrueOffsetOfChild(categoryElement, categoryId === categoryIds.current[0]));
        }
    }

    function getTrueOffsetOfChild(childElement: HTMLElement, isFirst: boolean) {
        const element = document.getElementById(rootId) as HTMLElement;
        // const restoHeader = document.getElementById(restaurantHeaderId) as HTMLElement;
        if (element == null) {
            return childElement.offsetTop;
        }
        if (isFirst) {
            return childElement.offsetTop - maxHeaderHeight;
        }
        return childElement.offsetTop - minHeaderHeight; //getComputedMargin();
    }

    function generateMenuJSXId(categoryId: number) {
        return menuIdPrefix + categoryId;
    }

    function generateHeaderJSXId(categoryId: number) {
        return headerIdPrefix + categoryId;
    }

    useEffect(() => {
        if (selectedCategoryId == null && menuCategories.length > 0) {
            setSelectedCategoryId(menuCategories[0].id);
        }
    }, [menuCategories]);

    function findCategoryElements() {
        const categoryElements = categoryIds.current.map((categoryId) => document.getElementById(generateMenuJSXId(categoryId)));
        return categoryElements.map((categoryElement, i) => {
            if (categoryElement) {
                return {
                    id: categoryElement.id,
                    offsetTop: getTrueOffsetOfChild(categoryElement, i === 0)
                }
            }
            return null;
        }).filter((categoryElement) => categoryElement !== null) as { id: string, offsetTop: number }[];
    }

    const state = {
        selectedCategoryId,
        selectCategoryManually,
        generateMenuJSXId,
        generateHeaderJSXId,
        activate,
        deactivate
    }

    return (
        <MenuCategoryContext.Provider value={state}>
            <Outlet />
        </MenuCategoryContext.Provider>
    )
}

export default MenuCategoryProvider;