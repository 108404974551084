export interface Table{
    id: number;
    isEnabled: boolean;
    tableId: number;
}

export const defaultTable: Table = {
    id: 0,
    isEnabled: false,
    tableId: 0
}